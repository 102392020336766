import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CompanyGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/company-group')
  }

  createGroup (payload) {
    return this.post('/company-group', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/company-group/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/company-group/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/company-group?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CompanyGroupProvider
