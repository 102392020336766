<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <CompanyGroupAdd
      ref="companyGroupAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-company-group="addCompanyGroup($event)" />
    <CompanyGroupDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-company-group="initialCompanyGroup"
      :selected-item.sync="selectedItem"
      @update-company-group="updateCompanyCenter($event)"
      @delete-item="openDeleteModal($event)" />
    <CompanyGroupFilter
      :search.sync="filter.searchCodeAndName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Company Group"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CompanyGroupAdd from '@/components/Customers/CompanyGroup/CompanyGroupAdd.vue'
import CompanyGroupDetail from '@/components/Customers/CompanyGroup/CompanyGroupDetail.vue'
import CompanyGroupFilter from '@/components/Customers/CompanyGroup/CompanyGroupFilter.vue'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const CompanyGroupService = new CompanyGroupProvider()

export default {
  components: {
    CompanyGroupAdd,
    CompanyGroupDetail,
    CompanyGroupFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'code', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'remark', label: 'Remark' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialCompanyGroup: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  mounted () {
    this.getCompanyGroup()
  },
  methods: {
    async getCompanyGroup () {
      try {
        const data = await CompanyGroupService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addCompanyGroup (val) {
      try {
        await CompanyGroupService.createGroup(val)
        this.resetFormAndToggleSideBar(this.$refs.companyGroupAdd)
        await this.getCompanyGroup()
        this.resetFormAndToggleSideBar(this.$refs.companyGroupAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Group has been added'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async updateCompanyCenter (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CompanyGroupService.updateGroup(val.id, payload)
        await this.getCompanyGroup()
        this.setDataInitialAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Group has been updated'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CompanyGroupService.deleteGroup(val.ID)
        await this.getCompanyGroup()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Group Type has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Company Group has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setDataInitialAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setDataInitialAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCompanyGroup = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCompanyGroup()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCompanyGroup()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
